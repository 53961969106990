import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

type Props = {
  isOpen: boolean;
  handleCloseDialog: () => void;
  reportMessage: string;
  onChangeReportMessage: (reportMessage: string) => void;
  onReport: (reportMessage: string) => void;
};

export default function ReportDialog(props: Props) {
  return (
    <Dialog
      open={props.isOpen}
      onClose={props.handleCloseDialog}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Report Image</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          id="reportMessage"
          label="Report Message"
          multiline={true}
          rows={4}
          type="text"
          fullWidth
          value={props.reportMessage}
          onChange={e => props.onChangeReportMessage(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleCloseDialog} color="primary">
          Cancel
        </Button>
        <Button onClick={() => props.onReport(props.reportMessage)} color="primary">
          Report
        </Button>
      </DialogActions>
    </Dialog>
  );
}
