import * as React from 'react';
import { AnnotationTask } from '../../Utils/Models/Task/Task';
import { AnnotationPrediction } from '../../Utils/Models/Prediction';
import { ImageWithPanel } from '../shared/ImageWithPanel';
import ImageContainerWithCanvas, {
  TransformState,
} from '../tasks/annotateRectangles/ImageContainerWithCanvas';
import { AnnotationSubmission } from '../../Utils/Models/Submission/AnnotationSubmission';
import { BBox } from 'geojson';
import { Rectangle } from '../tasks/annotateRectangles/Rectangle';
import RectangleWithoutHandles from '../tasks/annotateRectangles/RectangleWithoutHandles';

interface Props {
  task: AnnotationTask;
  submission: AnnotationSubmission;
  prediction: AnnotationPrediction | null;
}

interface State {
  transformState: TransformState;
}

export class AnnotateImageSubmissionUI extends React.Component<Props, State> {
  public state: State = {
    transformState: { top: 0, left: 0, scale: 1 },
  };

  updateTransform = (transformState: TransformState) => {
    this.setState({ transformState });
  };

  bboxToRectangle = (bbox: BBox): Rectangle => {
    return {
      offset: {
        width: bbox[0],
        height: bbox[1],
      },
      bounds: {
        width: bbox[2] - bbox[0],
        height: bbox[3] - bbox[1],
      },
    };
  };

  renderPrediction() {
    if (!this.props.prediction) {
      return <></>;
    }
    return this.props.prediction.result.featureCollection.features.map((feature, index) => {
      // annotations order by confidence
      const annotation = feature.properties!.annotations.sort(
        (a: any, b: any) => (b.confidence - a.confidence) * 100
      )[0].name;
      const rectangle = this.bboxToRectangle(feature.geometry.bbox!);
      console.log('', this.props.submission.result.drawingVariant.featureCollection.features);
      const isItemPresent =
        this.props.submission.result.drawingVariant.featureCollection.features.filter(
          f => f.properties.label === feature.properties!.label
        ).length === 1;
      return (
        <RectangleWithoutHandles
          key={String(index)}
          rectangle={rectangle}
          color={isItemPresent ? '#009900' : '#ff9900'} // green if present, yellow if missing
          label={`${annotation} (${isItemPresent ? 'korrekt' : 'verfehlt'})`}
          transformState={this.state.transformState}
        />
      );
    });
  }

  renderSubmission() {
    console.log('rendering...');
    return this.props.submission.result.drawingVariant.featureCollection.features
      .filter(
        feature =>
          // filter duplicates - label appears more than once
          this.props.submission.result.drawingVariant.featureCollection.features.filter(
            f => f.properties.label === feature.properties!.label
          ).length !== 1
      )
      .map((feature, index) => {
        const annotation = feature.properties!.annotations[0].name;
        const rectangle = this.bboxToRectangle(feature.geometry.bbox!);
        return (
          <RectangleWithoutHandles
            key={String(index)}
            rectangle={rectangle}
            color={this.props.prediction ? '#ff0000' : '#999999'}
            label={`${annotation}${this.props.prediction ? ' (inkorrekt)' : ''}`}
            transformState={this.state.transformState}
          />
        );
      });
  }

  render() {
    return (
      <ImageWithPanel>
        <ImageContainerWithCanvas
          mode={'overview'}
          imageSrc={this.props.task.params.attachment.url}
          onChange={() => {}}
          onFinish={() => {}}
          onCancel={() => {}}
          onTransform={this.updateTransform}
          innerRef={() => {}}
        >
          {this.renderSubmission()}
          {this.renderPrediction()}
        </ImageContainerWithCanvas>
      </ImageWithPanel>
    );
  }
}
