import * as React from 'react';
import FinishMessage from './FinishMessage';
import TaskComponent from '../TaskComponent';

import styled from 'styled-components';
import SingleTaxonomyForm from './SingleTaxonomyForm';
import Markdown from '../../shared/Markdown';
import { SingleTaxonomyTask } from '../../../Utils/Models/Task/Params/SingleTaxonomyTask';
import { TaxonomyTask } from '../../../Utils/Models/Task/Task';
import PinchZoomPan from 'react-responsive-pinch-zoom-pan';
import ImageContainer from './ImageContainer';
import { PanelHeader, Panel, ImageWithPanel } from '../../shared/ImageWithPanel';
import { Submission } from '../../../Utils/Models/Submission/Submission';
import {
  TaxonomyResult,
  TaxonomySubmission,
} from '../../../Utils/Models/Submission/TaxonomySubmission';

type Props = {
  userId: string;
  hashKey: string;
  task: TaxonomyTask;
  onDone: (submission: Submission) => void;
  onReport: (reportMessage: string) => void;
};

const StyledSingleTaxonomyForm = styled(SingleTaxonomyForm)`
  width: 100%;
`;

export default class CategorizeTaskUI extends TaskComponent<TaxonomyTask, TaxonomySubmission, Props> {
  state = {
    currentIndex: 0 as number,
    results: [] as TaxonomyResult[],
    isFinished: false as boolean,
    finishMessage: null as string | null,
  };

  getCurrentTaxonomy() {
    return this.props.task.params.taxonomies[this.state.currentIndex];
  }

  done = (results: string[]) => {
    const taxonomy = this.getCurrentTaxonomy();
    const newResult: TaxonomyResult = { results, name: taxonomy.name };
    this.setState({ results: this.state.results.concat(newResult) }, () => {
      this.skipToNext();
    });
  };

  report = (reportMessage: string) => {
    this.props.onReport(reportMessage);
    this.setState({ isFinished: true, finishMessage: 'Thanks for reporting!' });
  };

  skipToNext = () => {
    const { taxonomies } = this.props.task.params;
    if (this.state.currentIndex < taxonomies.length - 1) {
      const nextIndex = taxonomies.findIndex((taxonomy, index) => {
        return index > this.state.currentIndex && this.taxonomyIsShown(taxonomy, index);
      });
      if (taxonomies[nextIndex]) {
        this.setState({ currentIndex: nextIndex });
      } else {
        this.finish();
      }
    } else {
      this.finish();
    }
  };

  finish() {
    const submission: TaxonomySubmission = {
      userId: this.props.userId,
      hashKey: this.props.hashKey,
      taskId: this.props.task.id!,
      createdOn: new Date().toISOString(),
      type: this.props.task.type,
      result: {
        taxonomies: this.state.results,
      },
    };
    this.props.onDone(submission);
    this.setState({ isFinished: true, finishMessage: 'Thanks for playing!' });
  }

  taxonomyIsShown(taxonomy: SingleTaxonomyTask, index: number) {
    if (index === 0) {
      return true;
    }
    const { condition } = taxonomy;
    if (!condition) {
      return true;
    }
    const foundOtherTaxonomy = this.state.results.find(
      result => result.name === condition.taxonomy
    );
    if (!foundOtherTaxonomy) {
      return false;
    }
    return foundOtherTaxonomy.results.find(result => result === condition.contains);
  }

  renderForm(taxonomy: SingleTaxonomyTask) {
    return (
      <>
        <StyledSingleTaxonomyForm
          taxonomy={taxonomy}
          onDone={this.done}
          onSkip={this.skipToNext}
          onReport={this.report}
        >
          <PanelHeader>
            <Markdown>{taxonomy.description}</Markdown>
          </PanelHeader>
        </StyledSingleTaxonomyForm>
      </>
    );
  }

  renderCategorizeUI() {
    const taxonomy = this.getCurrentTaxonomy();
    return (
      <>
        <ImageContainer>
          <PinchZoomPan position={'center'}>
            <img src={this.props.task.params.attachment.url} alt="" />
          </PinchZoomPan>
        </ImageContainer>
        <Panel>{this.renderForm(taxonomy)}</Panel>
      </>
    );
  }

  render() {
    const bodyContent =
      this.state.isFinished && this.state.finishMessage ? (
        <FinishMessage finishMessage={this.state.finishMessage} />
      ) : (
        this.renderCategorizeUI()
      );
    return <ImageWithPanel>{bodyContent}</ImageWithPanel>;
  }
}
