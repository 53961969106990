import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';

type Props = {
  isOpen: boolean;
  handleCloseDialog: () => void;
  onDone: () => void;
};

export default function ConfirmationDialog(props: Props) {
  return (
    <Dialog
      open={props.isOpen}
      onClose={props.handleCloseDialog}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">{'Bestätigung'}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Bist Du sicher, dass Du alle gesuchten Objekte markeirt hast ?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleCloseDialog} color="primary">
          Nein
        </Button>
        <Button onClick={() => props.onDone()} color="primary">
          Ja
        </Button>
      </DialogActions>
    </Dialog>
  );
}
