import * as React from 'react';
import { Rectangle, Size } from './Rectangle';
import useAnnotationCanvas from './useAnnotationCanvas';

type Props = {
  x: number;
  y: number;
  width: number;
  height: number;
  factors: Rectangle;
  rectangle: Rectangle;
  setCornerHandlerColor: (color: string) => void;
  cornerHandlerColor: string;
  onChange: (newRectangle: Rectangle) => void;
  onFinish: (newRectangle: Rectangle) => void;
  onCancel: () => void;
  onZoom: () => void;
};

export default function ResizeHandle(props: Props) {
  const { rectangle, factors } = props;
  const [startRectangle, setStartRectangle] = React.useState<Rectangle | undefined>();

  const onStart = () => {
    console.log('Setting start rectangle to', props.rectangle);
    props.setCornerHandlerColor('#F8CB2C');
    setStartRectangle(props.rectangle);
  };

  const onFinish = () => {
    props.setCornerHandlerColor(props.cornerHandlerColor);
    props.onChange(props.rectangle);
    props.onZoom();
  };

  const onChange = (startOffset: Size, dragOffset: Size) => {
    if (!startRectangle) {
      return;
    }
    props.onChange({
      offset: {
        width: startRectangle.offset.width + dragOffset.width * factors.offset.width,
        height: startRectangle.offset.height + dragOffset.height * factors.offset.height,
      },
      bounds: {
        width: startRectangle.bounds.width + factors.bounds.width * dragOffset.width,
        height: startRectangle.bounds.height + factors.bounds.height * dragOffset.height,
      },
    });
  };

  const handlerStyle = {
    width: props.width,
    height: props.height,
    top: props.y,
    left: props.x,
    // backgroundColor: 'pink',
    position: 'absolute',
    transform: `translate(${rectangle.offset.width}px, ${rectangle.offset.height}px)`,
    opacity: 0.3,
    zIndex: 1,
  } as React.CSSProperties;

  const handlers = useAnnotationCanvas({
    onStart,
    onChange,
    onFinish,
    onCancel: props.onCancel,
  });

  return <div style={handlerStyle} {...handlers} />;
}
