import * as React from "react";
import marked from "marked";

export default function Markdown(props: { children?: string }) {
  if (!props.children) {
    return null;
  }
  const firstLineWithContent = props.children
    .split(/\n/)
    .find(line => line.replace(/\s/, "").length > 0);

  const whitespaceMatches =
    firstLineWithContent && firstLineWithContent.match(/^\s+/);
  const indentation =
    whitespaceMatches && new RegExp(`^${whitespaceMatches[0]}`);

  const lines = props.children
    .split(/\n/)
    .map(line => (indentation ? line.replace(indentation, "") : line))
    .join("\n");

  return <div dangerouslySetInnerHTML={{ __html: (marked as any)(lines) }} />;
}
