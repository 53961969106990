import * as React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import './App.css';
import TaskLoader from './components/tasks/TaskLoader';
import { SubmissionLoader } from './components/submissions/SubmissionLoader';
import { PredictionLoader } from './components/predictions/PredictionLoader';
import { OverviewLoader } from './components/overview/OverviewLoader';

// RequestAnimationFrame Polyfill for old Browsers
if (
  /iP(ad|hone|od).*OS/.test(window.navigator.userAgent) ||
  !window.requestAnimationFrame ||
  !window.cancelAnimationFrame
) {
  const globalAny: any = global;
  globalAny.requestAnimationFrame = (cb: any) => {
    setTimeout(cb, 0);
  };
}

class App extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/" render={() => <TaskLoader />} />
          <Route exact path="/submission" render={() => <SubmissionLoader />} />
          <Route exact path="/prediction" render={() => <PredictionLoader />} />
          <Route exact path="/overview" render={() => <OverviewLoader />} />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
