import bbox from '@turf/bbox';
import { DrawingFeature } from '../../../Utils/Models/AnnotateImage/Drawing';
import { Rectangle } from './Rectangle';
import { LineString } from 'geojson';
import { cloneDeep } from 'lodash';

export default function getRectangleForFeature(feature: DrawingFeature): Rectangle {
  const b = feature.bbox || bbox(feature.geometry);
  return {
    offset: {
      width: b[0],
      height: b[1],
    },
    bounds: {
      width: b[2] - b[0],
      height: b[3] - b[1],
    },
  };
}

export function getGeometryForRectangle(rectangle: Rectangle): LineString {
  const { offset, bounds } = cloneDeep(rectangle);
  const topLeft = [offset.width, offset.height];
  const bottomLeft = [topLeft[0], offset.height + bounds.height];
  const topRight = [offset.width + bounds.width, topLeft[1]];
  const bottomRight = [topRight[0], bottomLeft[1]];
  // topLeft, bottomLeft, topRight, bottomRight depend on the direction in which the rectangle was
  // drawn. Use min/max of x/y coordinates to calculate bbox, see below.
  // bbox must be included since it is used by the prediction algorithm.
  const coordinates = [topLeft, bottomLeft, bottomRight, topRight];
  const xValues = coordinates.map(c => c[0]);
  const yValues = coordinates.map(c => c[1]);
  return {
    coordinates,
    type: 'LineString',
    // The value of the bbox member [...], with the lowest values for all axes followed by the highest values.
    bbox: [
      Math.min(...xValues),
      Math.min(...yValues),
      Math.max(...xValues),
      Math.max(...yValues),
    ],
  };
}
