// Interface to the external app that shows our deployed React app in a webview.

const eventNames = [
  "Error",
  "Ready",
  "Started",
  "Quartile2",
  "Quartile3",
  "Quartile4",
  "Passback",
  "Impression",
  "Complete"
];

interface IWindow {
  webkit?: any;
  KwizzAdJI?: any;

  eventError?: () => void;
  eventReady?: () => void;
  eventStarted?: () => void;
  eventQuartile2?: () => void;
  eventQuartile3?: () => void;
  eventQuartile4?: () => void;
  eventPassback?: () => void;
  eventImpression?: () => void;
  eventComplete?: () => void;
}

declare var window: IWindow;

export function sendWebKitMessage(eventName: string, ...args: any[]) {
  try {
    const message = {
      arguments: args,
      event: eventName
    };
    if (
      typeof window.webkit === "object" &&
      window.webkit &&
      window.webkit.messageHandlers &&
      window.webkit.messageHandlers.KwizzAdJI
    ) {
      window.webkit.messageHandlers.KwizzAdJI.postMessage(message);
    }
  } catch (error) {
    // tslint:disable-next-line:no-console
    console.log("Error while posting message", error);
  }
}

// Sends a given event name + args to the native app / SDK that integrates Komet.

export function sendJIEvent(eventName: string, ...args: any[]) {
  if (typeof window.KwizzAdJI === "object") {
    if (typeof window.KwizzAdJI[eventName] === "function") {
      // tslint:disable-next-line:no-console
      console.log(
        `Sending '${eventName}' event to Kwizzad JI API: ${JSON.stringify(
          args
        )}`
      );
      try {
        if (args && args.length > 0) {
          window.KwizzAdJI[eventName](...args); // eslint-disable-line no-undef
        } else {
          window.KwizzAdJI[eventName](); // eslint-disable-line no-undef
        }
      } catch (error) {
        // tslint:disable-next-line:no-console
        console.log(
          `Could not send '${eventName}' event to JI API: ${String(error)}`
        );
      }
    } else {
      // tslint:disable-next-line:no-console
      console.log(
        `Ignored '${eventName}' event. JI API is initialized, but does not support this event.`
      );
    }
  } else {
    // tslint:disable-next-line:no-console
    console.log(`Ignored '${eventName}' event, JI API not available.`);
  }

  sendWebKitMessage(eventName, ...args);
}

export function startNativeChallenge() {
  const challenge = {
    completionType: "NEVER",
    originalChallenge: {
      challengeType: "native"
    },
    properties: {},
    sdkType: "",
    type: "native"
  };

  const challengeJSON = JSON.stringify(challenge);

  if (typeof window.KwizzAdJI !== "undefined") {
    if (typeof window.KwizzAdJI.nativeChallenge === "function") {
      window.KwizzAdJI.nativeChallenge(challengeJSON);
    }
  }

  sendWebKitMessage("nativeChallenge", challengeJSON);
}

export function registerNativeChallengeEvents(
  handler: (eventName: string) => void
) {
  eventNames.forEach(eventName => {
    (window as any)[`event${eventName}`] = () => handler(eventName);
  });
}

export function unregisterNativeChallengeEvents() {
  eventNames.forEach(eventName => {
    delete (window as any)[`event${eventName}`];
  });
}
