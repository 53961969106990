import * as React from 'react';
import Markdown from '../../shared/Markdown';
import { PanelHeader } from '../../shared/ImageWithPanel';
import { UIMode } from './UIMode';

export default function ExplanationPanelHeader(props: { mode: UIMode; instruction: string }) {
  const explanationForCurrentMode = {
    onboarding: props.instruction,
    overview: 'Markiere **alles**, was Du auf dem Bild findest.',
    drawingAnnotation: 'Markiere **alles**, was Du auf dem Bild findest.',
    confirmingAnnotation:
      'Verschiebe den Ausschnitt so, dass das Rechteck das Objekt exakt umrandet.',
    editingAnnotation: 'Korrigiere oder bestätige die Markierung.',
  }[props.mode];

  return (
    <PanelHeader>
      <Markdown>{explanationForCurrentMode}</Markdown>
    </PanelHeader>
  );
}
