import * as React from 'react';
import CancelButton from '../../../shared/buttons/CancelButton';
import DoneButton from '../../../shared/buttons/DoneButton';
import { FormFooter } from '../../../shared/Forms';

type Props = {
  canConfirm: boolean;
  onCancel: (zoomToDefaultScale: boolean) => void;
  onConfirm: () => void;
};

export default function CancelOrConfirmFooter(props: Props) {
  return (
    <FormFooter>
      <DoneButton color="#00BDE1" label="Stimmt so!" onClick={props.onConfirm} isSmall={true} />
      <CancelButton
        color="#ccc"
        label="Abbrechen"
        onClick={() => props.onCancel(true)}
        isSmall={true}
      />
    </FormFooter>
  );
}
