import * as React from 'react';
import { Task } from '../../Utils/Models/Task/Task';
import { Submission } from '../../Utils/Models/Submission/Submission';

export interface ITaskComponentProps<TaskT extends Task, SubmissionT extends Submission> {
  task: TaskT;
  userId: string;
  hashKey: string;
  onDone: (submission: SubmissionT) => void;
  onSkip: () => void;
  onReport: (reportMessage: string) => void;
}

export default class TaskComponent<
  TaskT extends Task,
  SubmissionT extends Submission,
  P extends {},
  State extends {} = {}
> extends React.Component<ITaskComponentProps<TaskT, SubmissionT> & P, State> {}
