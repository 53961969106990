import * as React from 'react';
import { compact } from 'lodash';
import {
  SingleTaxonomyTask,
  StringOrChoiceWithSubchoices,
} from '../../../Utils/Models/Task/Params/SingleTaxonomyTask';
import ReportDialog from '../../shared/ReportDialog';
import {
  Form,
  getPresetLabelColor,
  labelColors,
  FormFooter,
  SecondaryButtonContainer,
} from '../../shared/Forms';
import ChoiceFieldSet, { Choice } from '../../shared/ChoiceFieldSet';
import DoneButton from '../../shared/buttons/DoneButton';

type Props = {
  taxonomy: SingleTaxonomyTask;
  onSkip: () => void;
  onDone: (results: string[]) => void;
  onReport: (reportMessage: string) => void;
  children?: React.ReactNode;
  className?: string;
};

function getChoiceLabel(choice: StringOrChoiceWithSubchoices) {
  if (typeof choice === 'string') {
    return choice;
  }
  return choice.choice;
}

export default function SingleTaxonomyForm(props: Props) {
  const formRef = React.useRef<HTMLFormElement>(null);

  const [results, setResults] = React.useState<string[]>([]);
  const [isReportDialogOpen, setReportDialogOpen] = React.useState<boolean>(false);
  const [reportMessage, setReportMessage] = React.useState<string>('');

  const updateResults = () => {
    if (!formRef.current) {
      return;
    }
    const newResults = Array.from(formRef.current.elements).map(el => {
      if (!(el instanceof HTMLInputElement) || !el.checked) {
        return null;
      }

      return el.checked ? (props.taxonomy.allowMultiple ? el.name : el.value) : null;
    });

    setResults(compact(newResults));
  };

  const { name, allowMultiple } = props.taxonomy;
  const isReadyToSubmit = allowMultiple || results.length > 0;
  const choices: Choice[] = props.taxonomy.choices.map((choice, i) => {
    const label = getChoiceLabel(choice);
    return {
      label,
      isChecked: results.includes(label),
      colorWhenChecked: getPresetLabelColor(label) || labelColors[i % labelColors.length],
    };
  });

  return (
    <Form
      ref={formRef}
      onSubmitCapture={e => {
        e.preventDefault();
        props.onDone(results);
        setResults([]);
      }}
      className={props.className}
    >
      {props.children}

      {<ChoiceFieldSet {...{ allowMultiple, choices, name, onChange: updateResults }} />}

      <FormFooter>
        {/*
          It's important that the 'Done' button is first in the DOM, as it is auto-clicked on form
          submission (e.g. when pressing the 'Enter' key on a resktop). Clicking it submits the form.
        */}
        <DoneButton color="#22AF7E" disabled={!isReadyToSubmit} label="Fertig!" />

        <SecondaryButtonContainer
          buttonDefinitions={[
            { label: 'Überspringen', onClick: () => props.onSkip() },
            { label: 'Melden', onClick: () => setReportDialogOpen(true) },
          ]}
        />
      </FormFooter>

      <ReportDialog
        isOpen={isReportDialogOpen}
        handleCloseDialog={() => setReportDialogOpen(false)}
        reportMessage={reportMessage}
        onChangeReportMessage={setReportMessage}
        onReport={props.onReport}
      />
    </Form>
  );
}
