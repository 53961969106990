import * as React from 'react';
import ImageContainer from '../categorize/ImageContainer';
import useAnnotationCanvas from './useAnnotationCanvas';
import CustomPinchZoomPan from '../../shared/PinchZoomPan';
import PinchZoomPan from '../../shared/PinchZoomPan';
import { UIMode } from './UIMode';
import { createRectangle, isValidSize, Rectangle, Size } from './Rectangle';
import SimpleSchema from 'simpl-schema';

export type TransformState = {
  top: number;
  left: number;
  scale: number;
};

const TransformStateSchema = new SimpleSchema({
  top: Number,
  left: Number,
  scale: Number,
});

type Props = {
  imageSrc: string;
  mode: UIMode;
  onCancel: (zoomToDefaultScale: boolean) => void;
  onChange: (rectangle: Rectangle) => void;
  onFinish: (rectangle: Rectangle) => void;
  onTransform: (state: TransformState) => void;
  children?: React.ReactNode;
  innerRef: React.Ref<PinchZoomPan>;
};

export default function ImageContainerWithCanvas(props: Props) {
  const onChange = (startOffset: Size, dragOffset: Size) => {
    props.onChange(createRectangle(startOffset, dragOffset));
  };
  const onFinish = (startOffset: Size, dragOffset: Size) => {
    if (isValidSize(dragOffset)) {
      props.onFinish(createRectangle(startOffset, dragOffset));
    } else {
      props.onCancel(false);
    }
  };

  const handlers = useAnnotationCanvas({
    onChange,
    onFinish,
    onStart: () => {
      console.log('Start annotation');
    },
    onCancel: () => props.onCancel(false),
  });
  return (
    <ImageContainer {...(['overview', 'onboarding'].includes(props.mode) ? handlers : [])}>
      <CustomPinchZoomPan
        debug={false}
        position="center"
        onChange={(state: any) => {
          try {
            TransformStateSchema.validate(state);
            props.onTransform(state);
          } catch (e) {
            // ignore
          }
        }}
        ref={props.innerRef}
      >
        <img src={props.imageSrc} alt="" />
      </CustomPinchZoomPan>
      {props.children}
    </ImageContainer>
  );
}
