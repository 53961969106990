import * as React from 'react';
import { Rectangle } from './Rectangle';
import styled from 'styled-components';

const LabelCaption = styled.div`
  top: 0;
  left: 0;
  /* overflow: hidden; */
  text-align: center;
  position: absolute;
  color: white;
  font-size: 9px;
  font-weight: 600;
  line-height: 15px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  padding: 1px 2px;
  border-bottom-right-radius: 4px;

  transition: background-color 0.3s ease-out, opacity 0.5s ease-out;
`;

export default function AnnotationLabelCaption(props: {
  rectangle: Rectangle;
  label: string;
  color: string;
  isSelected?: boolean;
  onSelect?: (event: React.TouchEvent<HTMLElement>) => void;
}) {
  const r = props.rectangle;
  const handlers = {
    onTouchStart: (event: React.TouchEvent<HTMLElement>) => {
      event.stopPropagation(); // do not fire touch handlers of useAnnotationCanvas
      event.preventDefault();
      props.onSelect && props.onSelect(event);
    },
  };
  return (
    <LabelCaption
      {...(!props.isSelected ? handlers : null)}
      style={{
        // maxWidth: `${r.bounds.width - 4}px`,
        // height: `${Math.min(15, r.bounds.height - 4)}px`,
        transform: `translate(${r.offset.width + 2}px, ${r.offset.height + 2}px)`,
        backgroundColor: props.color,
        opacity: 1,
      }}
    >
      {props.label}
    </LabelCaption>
  );
}
