import { FeatureCollection, Feature, LineString } from 'geojson';

type Annotation = {
  name: string;
};

export type DrawingFeatureProperties = { annotations: Annotation[] };
export type DrawingFeature = Feature<LineString, DrawingFeatureProperties>;
export type DrawingFeatureWithoutGeometry = Feature<null, DrawingFeatureProperties>;

export type DrawingVariant = {
  featureCollection: FeatureCollection<LineString, DrawingFeatureProperties>;
};

/**
 * Specifies a tagged drawing on top of an image.
 *
 * See this documentation for more infos about GeoJSON:
 *
 * https://tools.ietf.org/html/rfc7946
 *
 * Using GeoJSON allows us to have drawings with multiple parts and varying geometry. A big number
 * of tools across all programming languages exist to work with this format, including
 *
 * - for various native platforms:
 *   - [MapboxGL Native](https://github.com/mapbox/mapbox-gl-native)
 *   - [Mapbox Mobile](https://www.mapbox.com/mobile/)
 * - for Java:
 *   - [GeoTools](https://www.geotools.org/)
 * - for JavaScript:
 *   - [Mapbox GL JS](https://docs.mapbox.com/mapbox-gl-js/api/)
 *   - [LeafletJS](https://leafletjs.com/)
 *   - [TurfJS](https://turfjs.org/)
 * - for Python:
 *   - [geojsonio](https://learn.adicu.com/geospatial/)
 *   - [geopandas](http://jonathansoma.com/lede/foundations-2017/classes/geopandas/mapping-with-geopandas/)
 *   - [shapely](https://pypi.org/project/Shapely/)
 *
 * GeoJSON coordinates are specified to use WGS84 as coordinate reference system. We allow pixel
 * and ratio coordinates to be used as CRS, too.
 */

export type Drawing = {
  variantsByCoordinateReferenceSystem: {
    wgs84?: DrawingVariant;
    pixels?: DrawingVariant;
    ratios?: DrawingVariant;
  };
};

export function createEmptyDrawingVariant(): DrawingVariant {
  return {
    featureCollection: {
      type: 'FeatureCollection',
      features: [],
    },
  };
}

export function createEmptyDrawing(): Drawing {
  return {
    variantsByCoordinateReferenceSystem: {
      pixels: createEmptyDrawingVariant(),
    },
  };
}

export function getPixelDrawingVariant(drawing: Drawing) {
  return drawing.variantsByCoordinateReferenceSystem.pixels;
}
