import * as React from 'react';
import { Size } from './Rectangle';

export type Props = {
  onStart: () => void;
  onChange: (startOffset: Size, dragOffset: Size) => void;
  onFinish: (startOffset: Size, dragOffset: Size) => void;
  onCancel: () => void;
};

function findTouchById(previousTouch: React.Touch | undefined, touchList: React.TouchList) {
  return (
    previousTouch && Array.from(touchList).find(t => t.identifier === previousTouch.identifier)
  );
}

/**
 * A component that allows to create a rectangle annotation by swiping with one finger.
 */

export default function useAnnotationCanvas<T>(
  props: Props
): {
  onTouchCancel: React.TouchEventHandler<T>;
  onTouchEnd: React.TouchEventHandler<T>;
  onTouchMove: React.TouchEventHandler<T>;
  onTouchStart: React.TouchEventHandler<T>;
} {
  React.useDebugValue('AnnotationCanvas');

  const { onChange } = props;
  // Offset of the drag start position, in screen coordinates
  const [startOffset, setStartOffset] = React.useState<Size | undefined>();

  // Offset describing how far the user has dragged, in screen coordinates
  const [dragOffset, setDragOffset] = React.useState<Size | undefined>();

  // Remembers the `Touch` object describing the last one-finger touch start event
  const [dragTouch, setDragTouch] = React.useState<React.Touch | undefined>();

  // Call `onChange` handler when dependent variables change
  React.useEffect(() => {
    startOffset && dragOffset && onChange(startOffset, dragOffset);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startOffset, dragOffset]);

  return {
    onTouchStart(event) {
      if (event.touches.length > 1) {
        return; // Ignore multi-touch events as they're not for drawing
      }
      event.preventDefault();
      const touch = event.touches[0];
      props.onStart();
      setStartOffset({ width: touch.clientX, height: touch.clientY });
      setDragTouch(touch);
      setDragOffset({ width: 0, height: 0 });
    },
    onTouchEnd(event) {
      if (event.touches.length > 1) {
        return; // Ignore multi-touch events as they're not for drawing
      }
      console.log('Touch end recognized');
      event.preventDefault();
      const touch = findTouchById(dragTouch, event.changedTouches);
      if (touch) {
        if (startOffset && dragOffset) {
          props.onFinish(startOffset, dragOffset);
        }
        setDragOffset(undefined);
        setDragTouch(undefined);
      }
    },
    onTouchMove(event) {
      if (event.touches.length > 1) {
        return; // Ignore multi-touch events as they're not for drawing
      }
      event.preventDefault();
      const touch = findTouchById(dragTouch, event.changedTouches);
      if (touch && startOffset) {
        setDragOffset({
          width: touch.clientX - startOffset.width,
          height: touch.clientY - startOffset.height,
        });
      }
    },
    onTouchCancel(event) {
      // Handle that the touch goes out of the screen, the view is closed while touched, …
      console.log('Cancel annotation');
      const touch = findTouchById(dragTouch, event.changedTouches);
      if (touch) {
        setDragOffset(undefined);
        setDragTouch(undefined);
        props.onCancel();
      }
    },
  };
}
