import styled from 'styled-components';

export const ImageWithPanel = styled.section`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  @media (max-height: 500px) {
    flex-direction: row;
  }
`;

export const Panel = styled.section`
  margin: 0.5rem 0;
  padding: 0 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;

  form {
    min-height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  @media (max-height: 500px) {
    flex: 0.5;
  }
`;

export const PanelHeader = styled.header`
  font-weight: 500;
`;
