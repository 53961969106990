import * as React from 'react';
import Button from './Button';

export type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  label: string;
  color: string;
  isSmall?: boolean;
  children?: React.ReactNode;
};

export default function ButtonIconWithLabel(props: Props) {
  return (
    <Button {...props}>
      <svg version="1.1" width="64" height="80">
        <g fill="none" fillRule="evenodd">
          <circle cx="31.739" cy="31.739" r={props.isSmall ? 24 : 32} fill={props.color} />
          {props.children}
          <text fill="#9C9C9C" fontSize="13" fontWeight="400" textAnchor="middle">
            <tspan x="32" y="76">
              {props.label}
            </tspan>
          </text>
        </g>
      </svg>
    </Button>
  );
}
