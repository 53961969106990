import * as React from 'react';
import { AnnotationTask } from '../../Utils/Models/Task/Task';
import { AnnotationPrediction } from '../../Utils/Models/Prediction';
import { ImageWithPanel } from '../shared/ImageWithPanel';
import ImageContainerWithCanvas, {
  TransformState,
} from '../tasks/annotateRectangles/ImageContainerWithCanvas';
import { BBox } from 'geojson';
import { Rectangle } from '../tasks/annotateRectangles/Rectangle';
import RectangleWithoutHandles from '../tasks/annotateRectangles/RectangleWithoutHandles';

interface Props {
  task: AnnotationTask;
  prediction: AnnotationPrediction;
}

interface State {
  transformState: TransformState;
}

export class AnnotateImagePredictionUI extends React.Component<Props, State> {
  public state: State = {
    transformState: { top: 0, left: 0, scale: 1 },
  };

  updateTransform = (transformState: TransformState) => {
    this.setState({ transformState });
  };

  bboxToRectangle = (bbox: BBox): Rectangle => {
    return {
      offset: {
        width: bbox[0],
        height: bbox[1],
      },
      bounds: {
        width: bbox[2] - bbox[0],
        height: bbox[3] - bbox[1],
      },
    };
  };

  renderPrediction() {
    console.log(this.props.prediction);
    return this.props.prediction[0].result.featureCollection.features.map(
      (feature: any, index: number) => {
        // annotations order by confidence
        const annotation = feature.properties!.annotations.sort(
          (a: any, b: any) => (b.confidence - a.confidence) * 100
        )[0].name;
        const rectangle = this.bboxToRectangle(feature.geometry.bbox!);
        const isItemPresent = true;
        return (
          <RectangleWithoutHandles
            key={String(index)}
            rectangle={rectangle}
            color={isItemPresent ? '#009900' : '#ff9900'} // green if present, yellow if missing
            label={`${annotation}`}
            transformState={this.state.transformState}
          />
        );
      }
    );
  }

  render() {
    return (
      <ImageWithPanel>
        <ImageContainerWithCanvas
          mode={'overview'}
          imageSrc={this.props.task.params.attachment.url}
          onChange={() => {}}
          onFinish={() => {}}
          onCancel={() => {}}
          onTransform={this.updateTransform}
          innerRef={() => {}}
        >
          {this.renderPrediction()}
        </ImageContainerWithCanvas>
      </ImageWithPanel>
    );
  }
}
