import * as React from 'react';
import SnackbarContent from '@material-ui/core/SnackbarContent';

import { sendJIEvent } from '../../Utils/callbacks/KwizzAdJI';
import { Task } from '../../Utils/Models/Task/Task';

import {
  createSubmission,
  getRandomTaskByUser,
  getTaskById,
  reportTask,
} from '../../Utils/API/BackendAPI';
import { getCurrentQueryParams } from '../../Utils/lib/queryParams';
import LoadingScreen from '../shared/LoadingScreen';
import { ITaskComponentProps } from './TaskComponent';
import CategorizeTaskUI from './categorize/CategorizeTaskUI';
import AnnotateRectanglesTaskUI from './annotateRectangles/AnnotateRectanglesTaskUI';
import { Submission } from '../../Utils/Models/Submission/Submission';

interface IState {
  task: Task | null;
  userId: string | null;
  hashKey: string | null;
  successRedirect: string | null;
  error: string | null;
}

const componentsForTaskTypes: {
  [type: string]: React.ComponentType<ITaskComponentProps<any, any>>;
} = {
  categorize: CategorizeTaskUI,
  // annotateImage: AnnotateImageTaskUI,
  annotateImage: AnnotateRectanglesTaskUI,
};

class TaskLoader extends React.Component {
  public state: IState = {
    task: null,
    userId: null,
    hashKey: null,
    successRedirect: null,
    error: null,
  };

  async componentDidMount() {
    const {
      userId,
      hashKey,
      taskId,
      successRedirect,
      debug = false,
    }: {
      userId: string;
      hashKey: string;
      taskId: string;
      successRedirect: string;
      debug: boolean;
    } = getCurrentQueryParams();

    // Mandatory field
    if (!userId) {
      this.setState({
        error: 'Please provide an {userId} parameter in the URL',
      });
      return;
    }
    // Mandatory field
    if (!hashKey) {
      this.setState({
        error: 'Please provide an {hashKey} parameter in the URL',
      });
      return;
    }

    let task;

    if (taskId) {
      try {
        task = await getTaskById(taskId, !debug);
      } catch (e) {
        if (e.status !== 409) {
          this.setState({
            error: 'Invalid {taskId} parameter in the URL.',
          });
          return;
        }
        console.log(`Task is locked, try to get random task. taskId: ${taskId}`);
      }
    }

    if (!task) {
      try {
        task = await getRandomTaskByUser(userId, !debug);
      } catch (e) {
        this.setState({
          error: 'No task available for current user.',
        });
        return;
      }
    }

    this.setState({ task, userId, hashKey, successRedirect });
  }

  render() {
    console.log(this.state.task);
    if (this.state.error) {
      return <SnackbarContent className="errorBar" message={this.state.error} />;
    }
    if (!this.state.task || !this.state.userId || !this.state.hashKey) {
      return <LoadingScreen />;
    }

    const TaskComponent = componentsForTaskTypes[this.state.task.type];

    return (
      <TaskComponent
        task={this.state.task as Task}
        userId={this.state.userId}
        hashKey={this.state.hashKey}
        onDone={async (submission: Submission) => {
          console.log(JSON.stringify(submission));
          await createSubmission(submission);
          if (typeof (window as any).KwizzAdJI === 'object') {
            sendJIEvent('cancelChallenge');
          } else if (this.state.successRedirect) {
            window.location.href = this.state.successRedirect;
          }
        }}
        onSkip={() => {
          window.alert('Task skipped!');
        }}
        onReport={async (reportMessage: string) => {
          console.log(reportMessage);
          await reportTask(this.state.task!.id!, { reportMessage, userId: this.state.userId! });
        }}
      />
    );
  }
}

export default TaskLoader;
