import styled from 'styled-components';

const ImageContainer = styled.div`
  flex: 1;
  height: 200px; /* This needs an arbitrary defined pixel height to do its flex: 1 correctly */
  position: relative;
  background: #545454;
  overflow: hidden;
  box-shadow: inset 0px -5px 20px 0px rgba(0, 0, 0, 0.25);

  button {
    /* background-image: none !important;
    background-color: white !important;
    border: none !important;
    border-radius: 24px;
    width: 40px;
    height: 40px; */
    display: none;
  }

  img {
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.7);
  }

  @media (max-height: 500px) {
    height: 100%;
  }
`;

export default ImageContainer;
