import * as React from 'react';
import ButtonIconWithLabel, { Props } from './ButtonIconWithLabel';

export default function CancelButton(props: Props) {
  const icon = (
    <g transform="translate(0, 8)" fill="white">
      <path
        d="M25.8773046,32.7292504 C25.8167121,32.7072168 25.734086,32.6668218 25.6936911,32.6374437 C25.5798507,32.5584898 19.6528055,26.6167556 19.5977214,26.5267849 C19.4526667,26.2935957 19.4710281,25.9832889 19.6399525,25.7592803 C19.7593014,25.6013727 25.4605019,18.772785 25.5523086,18.677306 C25.8258928,18.392705 26.2849267,18.4037218 26.547494,18.6993396 C26.5970697,18.7544237 26.655826,18.8462304 26.6796958,18.9031506 L26.7200908,19.0041381 L26.7255992,20.9798198 L26.7311076,22.9573377 L26.7715026,22.9683545 C26.8779984,22.9940604 27.4875954,23.0730142 27.7758686,23.096884 C29.6101679,23.2529555 31.4481395,22.9775352 33.1704345,22.2889844 C35.5574106,21.3360301 37.6946722,19.5659955 39.45369,17.0853766 C40.2358837,15.9818592 40.931779,14.7645014 41.6276743,13.2845762 C41.7580399,13.010992 41.8461744,12.9063323 42.0224434,12.820034 C42.1307754,12.7686222 42.1601536,12.7612776 42.3088806,12.7612776 C42.4557714,12.7612776 42.4869857,12.766786 42.5934816,12.8181978 C42.8083094,12.9210214 42.9625448,13.132177 42.9900868,13.3580216 C43.0084482,13.5140932 42.8542128,14.5937408 42.6926329,15.4603967 C41.8608635,19.8873192 40.1202071,23.2749891 37.5257478,25.5058936 C35.5776081,27.1804492 33.1796152,28.1976682 30.4566264,28.5043028 C29.8837521,28.5704037 29.6689243,28.5832566 28.9656844,28.5924373 C28.1412596,28.6052903 27.5794021,28.5777482 26.87249,28.4951221 L26.7659942,28.4822692 L26.7623219,30.3716525 L26.7568135,32.2610359 L26.696221,32.384057 C26.5860529,32.6099016 26.3804057,32.7421034 26.1141661,32.7586286 C26.0150148,32.7659731 25.9617668,32.7586286 25.8773046,32.7292504 Z"
        id="Path"
        transform="translate(31.245789, 22.761278) scale(1, -1) translate(-31.245789, -22.761278) "
      />
    </g>
  );
  return <ButtonIconWithLabel {...props}>{icon}</ButtonIconWithLabel>;
}
