import styled from 'styled-components';

const Button = styled.button`
  border: none;
  background: none;
  outline: none;
  cursor: pointer;

  :disabled {
    opacity: 0.5;
  }

  transition: opacity 0.3s ease-out;
`;

export default Button;
