import { Task } from '../Models/Task/Task';
import { TaskReport } from '../Models/Task/ReportTask';
import HttpException from '../lib/HttpException';
import { Submission } from '../Models/Submission/Submission';
import { Prediction } from '../Models/Prediction';

const baseUrl = process.env.REACT_APP_ENTION_API_BASE_URL;

async function fetchJSON(url: string, init?: RequestInit) {
  const requestInit = init || {};
  requestInit.headers = requestInit.headers || {};
  Object.assign(requestInit.headers, {
    Accept: 'application/json',
  });
  const response = await fetch(url, requestInit);
  if (!response.ok) {
    throw new HttpException(response.status, await response.text());
  }
  return response.json();
}

function postJSON(url: string, data: any) {
  return fetchJSON(url, {
    body: JSON.stringify(data),
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

export async function getTaskById(taskId: string, lock: boolean = false): Promise<Task> {
  const url = `${baseUrl}/tasks/${taskId}?lock=${lock}`;
  return await fetchJSON(url);
}

export async function getRandomTaskByUser(userId: string, lock: boolean = false): Promise<Task> {
  const url = `${baseUrl}/tasks/random?userId=${userId}&lock=${lock}`;
  return await fetchJSON(url);
}

export async function createSubmission(submission: Submission) {
  const url = `${baseUrl}/submissions`;
  return await postJSON(url, submission);
}

export async function reportTask(taskId: string, report: TaskReport) {
  const url = `${baseUrl}/tasks/${taskId}/reports`;
  return await postJSON(url, report);
}

export async function getPredictionsByTaskId(
  taskId: string,
  authUsername: string,
  authPassword: string
): Promise<Prediction> {
  const authHeader = new Headers();
  authHeader.append('Authorization', `Basic ${btoa(`${authUsername}:${authPassword}`)}`);
  const configInit: RequestInit = {
    method: 'GET',
    headers: authHeader,
  };
  const url = `${baseUrl}/predictions/${taskId}`;
  return await fetchJSON(url, configInit);
}

export async function getSubmissionById(
  id: string
): Promise<{
  task: Task;
  submission: Submission;
  prediction?: Prediction;
}> {
  const url = `${baseUrl}/submissions?id=${id}`;
  return await fetchJSON(url);
}

export async function getSubmissionByUser(
  userId: string
): Promise<
  {
    task: Task;
    submission: Submission;
  }[]
> {
  const url = `${baseUrl}/submissions?userId=${userId}`;
  return await fetchJSON(url);
}
