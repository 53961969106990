import * as React from "react";
import { Dots } from "react-activity";
import "react-activity/lib/Dots/Dots.css";
import styled from "styled-components";

type Props = {
  message?: string;
};

const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20em;
  padding: 1em;
  text-align: center;
`;

function LoadingScreen(props: Props) {
  return (
    <Container>
      <Dots size={30} />
    </Container>
  );
}

export default LoadingScreen;
