import * as React from 'react';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { AnnotationTask, Task } from '../../Utils/Models/Task/Task';

import { getPredictionsByTaskId, getTaskById } from '../../Utils/API/BackendAPI';
import { getCurrentQueryParams } from '../../Utils/lib/queryParams';
import LoadingScreen from '../shared/LoadingScreen';
import { AnnotationPrediction, Prediction } from '../../Utils/Models/Prediction';
import { AnnotateImagePredictionUI } from './AnnotateImagePredictionUI';

interface IState {
  task: Task | null;
  prediction: Prediction | null;
  taskId: string | null;
  error: string | null;
}

export class PredictionLoader extends React.Component {
  public state: IState = {
    task: null,
    prediction: null,
    taskId: null,
    error: null,
  };

  async componentDidMount() {
    const {
      taskId,
      authUsername,
      authPassword,
    }: {
      taskId: string;
      authUsername: string;
      authPassword: string;
    } = getCurrentQueryParams();

    // Mandatory field
    if (!taskId) {
      this.setState({
        error: 'Please provide a {taskId} parameter in the URL',
      });
      return;
    }

    // Mandatory field
    if (!authUsername) {
      this.setState({
        error: 'Please provide a {authUsername} parameter in the URL',
      });
      return;
    }

    // Mandatory field
    if (!authPassword) {
      this.setState({
        error: 'Please provide a {authPassword} parameter in the URL',
      });
      return;
    }

    let prediction;
    try {
      prediction = await getPredictionsByTaskId(taskId, authUsername, authPassword);
    } catch (e) {
      this.setState({
        error: 'Invalid {taskId} parameter in the URL.',
      });
      return;
    }

    let task;
    try {
      task = await getTaskById(taskId);
    } catch (e) {
      this.setState({
        error: 'Invalid {taskId} parameter in the URL.',
      });
      return;
    }

    this.setState({
      taskId,
      task,
      prediction,
    });
  }

  render() {
    if (this.state.error) {
      return <SnackbarContent className="errorBar" message={this.state.error} />;
    }
    if (!this.state.task || !this.state.prediction || !this.state.taskId) {
      return <LoadingScreen />;
    }

    return (
      <AnnotateImagePredictionUI
        task={this.state.task as AnnotationTask}
        prediction={this.state.prediction as AnnotationPrediction}
      />
    );
  }
}
