export function parseQueryParams(search: string): any {
  const params: any = {};
  search
    .slice(search.indexOf("?") + 1)
    .split("&")
    .forEach(hash => {
      const [key, val] = hash.split("=");
      params[key] = decodeURIComponent(val);
    });
  return params;
}

export function getCurrentQueryParams(): any {
  if (window.location.hash.match(/\?/)) {
    return parseQueryParams(
      window.location.hash.replace(/^.*#/, "").replace(/^.*\?/, "")
    );
  }
  if (window.location.search.match(/\?/)) {
    return parseQueryParams(window.location.search.replace(/^\?/, ""));
  }
  return {};
}
