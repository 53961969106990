import * as React from 'react';
import styled from 'styled-components';
import AnnotationLabelCaption from './AnnotationLabelCaption';
import { brighten } from '../../../Utils/lib/colors';
import { TransformState } from './ImageContainerWithCanvas';
import { Rectangle } from './Rectangle';

type Props = {
  rectangle: Rectangle;
  label: string;
  color: string;
  transformState: TransformState;
};

/**
 * Returns given rectangle, but with flipped signs if it has negative height or width.
 */
function sanitizeRectangle(rectangle: Rectangle) {
  const { bounds, offset } = rectangle;

  return {
    bounds: {
      width: Math.abs(bounds.width),
      height: Math.abs(bounds.height),
    },
    offset: {
      width: bounds.width < 0 ? offset.width - Math.abs(bounds.width) : offset.width,
      height: bounds.height < 0 ? offset.height - Math.abs(bounds.height) : offset.height,
    },
  };
}

export function transformRectangle(r: Rectangle, t: TransformState) {
  const rectangle = {
    bounds: {
      width: r.bounds.width * t.scale,
      height: r.bounds.height * t.scale,
    },
    offset: {
      width: r.offset.width * t.scale + t.left,
      height: r.offset.height * t.scale + t.top,
    },
  };
  // normalize - offset is always top left and bounds are always positive
  const normalized = {
    offset: {
      width:
        rectangle.bounds.width > 0
          ? rectangle.offset.width
          : rectangle.offset.width + rectangle.bounds.width,
      height:
        rectangle.bounds.height > 0
          ? rectangle.offset.height
          : rectangle.offset.height + rectangle.bounds.height,
    },
    bounds: {
      width: Math.abs(rectangle.bounds.width),
      height: Math.abs(rectangle.bounds.height),
    },
  };
  return normalized;
}

const Svg = styled.svg`
  transition: opacity 0.2s ease-out;
  /* pointer-events: none; */
  position: absolute;
  top: 0;
  left: 0;
`;

function RectangleWithoutHandles(props: Props) {
  const { color } = props;

  // Transform from model to screen coordinates
  const { bounds, offset } = transformRectangle(
    sanitizeRectangle(props.rectangle),
    props.transformState,
  );
  const brightColor = brighten(color);
  const innerWidth = Math.max(bounds.width - 4, 0);
  const innerHeight = Math.max(bounds.height - 4, 0);

  const [, setCornerHandlerColor] = React.useState<string>(brightColor);
  React.useEffect(() => {
    setCornerHandlerColor(brightColor);
  }, [brightColor]);

  return (
    <>
      <Svg
        width={`${bounds.width + 4}px`}
        height={`${bounds.height + 4}px`}
        viewBox={`0 0 ${bounds.width + 4} ${bounds.height + 4}`}
        version="1.1"
        style={{
          transform: `translate(${offset.width}px, ${offset.height}px)`,
          opacity: 1,
        }}
      >
        <rect
          id="rectangle"
          stroke={brightColor}
          strokeWidth="1"
          fill={color}
          fillOpacity={0.2}
          x={2}
          y={2}
          width={innerWidth}
          height={innerHeight}
        />

        <g id="grid" stroke={brightColor} strokeWidth={1} opacity="0.2">
          <g id="horizontal-grid">
            {[0, 1].map(i => (
              <rect key={i} x={((i + 1) * innerWidth) / 3} y={2} width={0.5} height={innerHeight}/>
            ))}
          </g>

          <g id="vertical-grid">
            {[0, 1].map(i => (
              <rect key={i} y={((i + 1) * innerHeight) / 3} x={2} width={innerWidth} height={0.5}/>
            ))}
          </g>
        </g>
      </Svg>

      <AnnotationLabelCaption
        rectangle={{ bounds, offset }}
        label={props.label}
        color={props.color}
        isSelected={false}
        onSelect={() => {
        }}
      />
    </>
  );
}

export default RectangleWithoutHandles;
