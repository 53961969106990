import * as React from 'react';
import ButtonIconWithLabel, { Props } from './ButtonIconWithLabel';

export default function DeleteButton(props: Props) {
  const icon = (
    <g transform="translate(14.000000, 14)" fill="white">
      <g transform="translate(17.000000, 17.500000) rotate(-43.000000) translate(-17.000000, -17.500000) translate(5.000000, 6.000000)">
        <rect x="10" y="0" width="4" height="22.7387224" rx="2" />
        <rect
          transform="translate(12.000000, 11.369361) rotate(-270.000000) translate(-12.000000, -11.369361) "
          x="10"
          y="2.27373675e-13"
          width="4"
          height="22.7387224"
          rx="2"
        />
      </g>
    </g>
  );
  return <ButtonIconWithLabel {...props}>{icon}</ButtonIconWithLabel>;
}
