import * as React from 'react';
import { Form, labelColors } from '../../shared/Forms';
import ChoiceFieldSet, { Choice } from '../../shared/ChoiceFieldSet';
import ConfirmationDialog from '../../shared/ConfirmationDialog';

type Props = {
  className?: string;
  annotationNames: string[];
  onChangeAnnotationName: (objectType: string) => void;
  selectedAnnotationName?: string;
  isReadyToSubmit: boolean;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  onSubmit: () => void;
};

export default function AnnotationNameForm(props: Props) {
  const formRef = React.useRef<HTMLFormElement>(null);
  const [isConfirmationDialogOpen, setConfirmationDialogOpen] = React.useState<boolean>(false);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!formRef.current) {
      return;
    }
    console.log(event.currentTarget.value);
    props.onChangeAnnotationName(event.currentTarget.value);
  };

  const choices: Choice[] = props.annotationNames.map((type, i) => {
    return {
      label: type,
      isChecked: type === props.selectedAnnotationName,
      colorWhenChecked: labelColors[i % labelColors.length],
    };
  });

  return (
    <>
      <Form
        ref={formRef}
        onSubmitCapture={e => {
          e.preventDefault();
          setConfirmationDialogOpen(true);
        }}
        className={props.className}
      >
        {props.header}
        {
          <ChoiceFieldSet
            {...{ choices, onChange, allowMultiple: false, name: 'annotationName' }}
          />
        }
        {props.footer}
      </Form>
      <ConfirmationDialog
        isOpen={isConfirmationDialogOpen}
        handleCloseDialog={() => setConfirmationDialogOpen(false)}
        onDone={() => props.onSubmit()}
      />
    </>
  );
}
