import * as React from 'react';
import styled from 'styled-components';
import AnnotationLabelCaption from './AnnotationLabelCaption';
import { brighten } from '../../../Utils/lib/colors';
import { TransformState } from './ImageContainerWithCanvas';
import ResizeHandle from './ResizeHandle';
import { isValidSize, Rectangle, Size } from './Rectangle';

type Props = {
  className?: string;
  rectangle: Rectangle;
  label: string;
  color: string;
  transformState: TransformState;
  onSelect?: (event: React.TouchEvent<SVGSVGElement | HTMLElement>) => void;
  onResize: (newRectangle: Rectangle) => void;
  onFinish: (newRectangle: Rectangle) => void;
  onZoom: () => void;
  isSelected?: boolean;
};

const CornerHandle = (props: React.SVGAttributes<SVGPathElement>) => (
  <path d={`M5,0 L20,0 L20,5 L5,5 L5,20 L0,20 L0,0 L5,0 Z`} {...props} />
);

/**
 * Returns given rectangle, but with flipped signs if it has negative height or width.
 */
function sanitizeRectangle(rectangle: Rectangle) {
  const { bounds, offset } = rectangle;

  return {
    bounds: {
      width: Math.abs(bounds.width),
      height: Math.abs(bounds.height),
    },
    offset: {
      width: bounds.width < 0 ? offset.width - Math.abs(bounds.width) : offset.width,
      height: bounds.height < 0 ? offset.height - Math.abs(bounds.height) : offset.height,
    },
  };
}

export function invertTransform(t: TransformState) {
  return {
    scale: 1 / t.scale,
    top: -t.top / t.scale,
    left: -t.left / t.scale,
  };
}

export function transformRectangle(r: Rectangle, t: TransformState) {
  const rectangle = {
    bounds: {
      width: r.bounds.width * t.scale,
      height: r.bounds.height * t.scale,
    },
    offset: {
      width: r.offset.width * t.scale + t.left,
      height: r.offset.height * t.scale + t.top,
    },
  };
  // normalize - offset is always top left and bounds are always positive
  const normalized = {
    offset: {
      width:
        rectangle.bounds.width > 0
          ? rectangle.offset.width
          : rectangle.offset.width + rectangle.bounds.width,
      height:
        rectangle.bounds.height > 0
          ? rectangle.offset.height
          : rectangle.offset.height + rectangle.bounds.height,
    },
    bounds: {
      width: Math.abs(rectangle.bounds.width),
      height: Math.abs(rectangle.bounds.height),
    },
  };
  return normalized;
}

const Svg = styled.svg`
  transition: opacity 0.2s ease-out;
  /* pointer-events: none; */
  position: absolute;
  top: 0;
  left: 0;
`;

function RectangleWithHandles(props: Props) {
  const { color } = props;

  // Transform from model to screen coordinates
  const { bounds, offset } = transformRectangle(
    sanitizeRectangle(props.rectangle),
    props.transformState
  );
  const brightColor = brighten(color);
  const innerWidth = Math.max(bounds.width - 4, 0);
  const innerHeight = Math.max(bounds.height - 4, 0);
  const resizeHandlerSize: Size = { height: 40, width: 40 };
  const resizeHandlesProps = [
    {
      // top right
      y: -resizeHandlerSize.height / 2,
      x: bounds.width - resizeHandlerSize.width / 2,
      factors: {
        offset: { width: 0, height: 1 },
        bounds: { width: 1, height: -1 },
      },
    },
    {
      // top left
      y: -resizeHandlerSize.height / 2,
      x: -resizeHandlerSize.width / 2,
      factors: {
        offset: { width: 1, height: 1 },
        bounds: { width: -1, height: -1 },
      },
    },
    {
      // bottom left
      y: bounds.height - resizeHandlerSize.height / 2,
      x: -resizeHandlerSize.width / 2,
      factors: {
        offset: { width: 1, height: 0 },
        bounds: { width: -1, height: 1 },
      },
    },
    {
      // bottom right
      y: bounds.height - resizeHandlerSize.height / 2,
      x: bounds.width - resizeHandlerSize.width / 2,
      factors: {
        offset: { width: 0, height: 0 },
        bounds: { width: 1, height: 1 },
      },
    },
    {
      // center
      y: resizeHandlerSize.height / 2,
      x: resizeHandlerSize.width / 2,
      factors: {
        offset: { width: 1, height: 1 },
        bounds: { width: 0, height: 0 },
      },
      width: bounds.width - resizeHandlerSize.width,
      height: bounds.height - resizeHandlerSize.height,
    },
  ];

  const [cornerHandlerColor, setCornerHandlerColor] = React.useState<string>(brightColor);
  React.useEffect(() => {
    setCornerHandlerColor(brightColor);
  }, [brightColor]);

  const resizeHandles = (
    <>
      {resizeHandlesProps.map((p, i) => (
        <ResizeHandle
          key={i}
          x={p.x}
          y={p.y}
          width={p.width || resizeHandlerSize.width}
          height={p.height || resizeHandlerSize.height}
          factors={p.factors}
          rectangle={{ bounds, offset }}
          setCornerHandlerColor={setCornerHandlerColor}
          cornerHandlerColor={brightColor}
          onChange={props.onResize}
          onFinish={props.onResize}
          onCancel={() => {}}
          onZoom={props.onZoom}
        />
      ))}
    </>
  );

  return (
    <>
      <Svg
        width={`${bounds.width + 4}px`}
        height={`${bounds.height + 4}px`}
        viewBox={`0 0 ${bounds.width + 4} ${bounds.height + 4}`}
        version="1.1"
        style={{
          transform: `translate(${offset.width}px, ${offset.height}px)`,
          opacity: 1,
        }}
        onTouchEndCapture={props.onSelect}
      >
        <rect
          id="rectangle"
          stroke={props.isSelected ? 'white' : brightColor}
          strokeWidth="1"
          fill={props.isSelected ? 'white' : color}
          fillOpacity={0.2}
          x={2}
          y={2}
          width={innerWidth}
          height={innerHeight}
        />

        <g id="grid" stroke={brightColor} strokeWidth={1} opacity="0.2">
          <g id="horizontal-grid">
            {[0, 1].map(i => (
              <rect key={i} x={((i + 1) * innerWidth) / 3} y={2} width={0.5} height={innerHeight} />
            ))}
          </g>

          <g id="vertical-grid">
            {[0, 1].map(i => (
              <rect key={i} y={((i + 1) * innerHeight) / 3} x={2} width={innerWidth} height={0.5} />
            ))}
          </g>
        </g>

        <g fill={cornerHandlerColor} stroke="none" id="handles">
          <CornerHandle id="top-left" />
          <CornerHandle id="top-right" transform={`translate(${bounds.width} 0) rotate(90)`} />
          <CornerHandle
            id="bottom-right"
            transform={`translate(${bounds.width} ${bounds.height}) rotate(180)`}
          />
          <CornerHandle id="bottom-left" transform={`translate(0 ${bounds.height}) rotate(270)`} />
        </g>
      </Svg>

      {props.isSelected && isValidSize(bounds) && resizeHandles}

      <AnnotationLabelCaption
        rectangle={{ bounds, offset }}
        label={props.label}
        color={props.color}
        isSelected={props.isSelected}
        onSelect={props.onSelect}
      />
    </>
  );
}

export default RectangleWithHandles;
