import * as React from 'react';

import DoneButton from '../../../shared/buttons/DoneButton';
import DeleteButton from '../../../shared/buttons/DeleteButton';
import { FormFooter } from '../../../shared/Forms';

type Props = {
  onDelete: () => void;
  onConfirm: () => void;
};

export default function DeleteOrConfirmFooter(props: Props) {
  return (
    <FormFooter>
      <DoneButton color="#00BDE1" label="Stimmt so!" onClick={props.onConfirm} isSmall={true} />
      <DeleteButton color="#FD7575" label="Löschen" onClick={props.onDelete} isSmall={true} />
    </FormFooter>
  );
}
