import * as React from 'react';
import SnackbarContent from '@material-ui/core/SnackbarContent';

import { Task } from '../../Utils/Models/Task/Task';
import { getSubmissionByUser } from '../../Utils/API/BackendAPI';
import { getCurrentQueryParams } from '../../Utils/lib/queryParams';
import LoadingScreen from '../shared/LoadingScreen';
import { Submission } from '../../Utils/Models/Submission/Submission';
import OverviewUI from './OverviewUI';

interface IState {
  submissionsAndTasks:
    | {
        task: Task;
        submission: Submission;
      }[]
    | null;
  userId: string | null;
  successRedirect: string | null;
  error: string | null;
}

export class OverviewLoader extends React.Component {
  public state: IState = {
    submissionsAndTasks: null,
    userId: null,
    successRedirect: null,
    error: null,
  };

  async componentDidMount() {
    const {
      userId,
    }: {
      userId: string;
    } = getCurrentQueryParams();

    // Mandatory field
    if (!userId) {
      this.setState({
        error: 'Please provide a {userId} parameter in the URL',
      });
      return;
    }

    let response;
    try {
      response = await getSubmissionByUser(userId);
    } catch (e) {
      this.setState({
        error: 'Please complete a task first to see its status in this overview',
      });
      return;
    }

    this.setState({
      userId,
      submissionsAndTasks: response,
    });
  }

  render() {
    if (this.state.error) {
      return <SnackbarContent className="errorBar" message={this.state.error} />;
    }
    if (!this.state.submissionsAndTasks || !this.state.userId) {
      return <LoadingScreen />;
    }
    return <OverviewUI submissionsAndTasks={this.state.submissionsAndTasks} />;
  }
}
