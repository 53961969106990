import * as React from "react";
import styled from "styled-components";

const Container = styled.div`
  font-size: 200%;
`;

type Props = {
  finishMessage: string;
};

export default function FinishMessage(props: Props) {
  return (
    <Container>
      {props.finishMessage}
      <span role="img" aria-label="">
        🤘🏽
      </span>
    </Container>
  );
}
