import * as React from 'react';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { AnnotationTask, Task } from '../../Utils/Models/Task/Task';

import { getSubmissionById } from '../../Utils/API/BackendAPI';
import { getCurrentQueryParams } from '../../Utils/lib/queryParams';
import LoadingScreen from '../shared/LoadingScreen';
import { Submission } from '../../Utils/Models/Submission/Submission';
import { AnnotationPrediction, Prediction } from '../../Utils/Models/Prediction';
import { AnnotateImageSubmissionUI } from './AnnotateImageSubmissionUI';
import { AnnotationSubmission } from '../../Utils/Models/Submission/AnnotationSubmission';

interface IState {
  task: Task | null;
  submission: Submission | null;
  prediction: Prediction | null;
  id: string | null;
  successRedirect: string | null;
  error: string | null;
}

export class SubmissionLoader extends React.Component {
  public state: IState = {
    task: null,
    submission: null,
    prediction: null,
    id: null,
    successRedirect: null,
    error: null,
  };

  async componentDidMount() {
    console.log('Staring....');

    const {
      id,
    }: {
      id: string;
    } = getCurrentQueryParams();

    // Mandatory field
    if (!id) {
      this.setState({
        error: 'Please provide a {id} parameter in the URL',
      });
      return;
    }

    let response;
    try {
      response = await getSubmissionById(id);
    } catch (e) {
      this.setState({
        error: 'Invalid {id} parameter in the URL.',
      });
      return;
    }

    this.setState({
      id,
      task: response.task,
      submission: response.submission,
      prediction: response.prediction,
    });
  }

  render() {
    if (this.state.error) {
      return <SnackbarContent className="errorBar" message={this.state.error} />;
    }
    if (!this.state.task || !this.state.submission || !this.state.id) {
      return <LoadingScreen />;
    }

    return (
      <AnnotateImageSubmissionUI
        task={this.state.task as AnnotationTask}
        submission={this.state.submission as AnnotationSubmission}
        prediction={this.state.prediction as AnnotationPrediction}
      />
    );
  }
}
