import * as React from 'react';
import styled from 'styled-components';

import { Task } from '../../Utils/Models/Task/Task';
import { Submission } from '../../Utils/Models/Submission/Submission';
import { TaxonomySubmission } from '../../Utils/Models/Submission/TaxonomySubmission';
import dateformat from 'dateformat';

export const ListContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 500px;
  margin: auto;
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  list-style-type: none;
  width: 100%;
  padding: 0;
  margin: 1em;
  overflow: hidden;
`;

export const ListItem = styled.li`
  width: 100%;
  padding: 0.5em 0;
  border-bottom: 1px solid #a9a9a9;
`;

export const ListMain = styled.span`
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
`;

export const ListItemImg = styled.img`
  width: 125px;
  height: 100px;
  border-radius: 5%;
`;

export const ListItemInfo = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 0.5em;
  height: 100px;
  overflow: hidden;
`;

export const ListItemTitle = styled.span`
  font-size: 16px;
  font-weight: bold;
`;

export const ListItemExtraData = styled.span`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  align-items: center;
`;

export const ListItemStatus = styled.span`
  padding: 0.3em;
  border-radius: 15%;
  background-color: orange;
  color: white;
`;

export const ListItemDate = styled.span`
  font-size: 14px;
  color: gray;
  text-align: end;
`;

export const TaxonomyList = styled.span`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
`;

export const ListTaxonomyTags = styled.span`
  justify-content: space-evenly;
  height: 0.75em;
  line-height: 0.75em;
  color: white;
  cursor: pointer;
  font-weight: 700;
  margin: 0.25rem;
  padding: 0.5em;
  border-radius: 1em;
  background: rgb(84, 84, 84);
  align-items: center;
`;

interface Props {
  submissionsAndTasks: {
    task: Task;
    submission: Submission;
  }[];
}

export default function OverviewUI(props: Props) {
  document.getElementById('root')!.style.overflow = 'auto';

  props.submissionsAndTasks.sort(
    (a: any, b: any) =>
      new Date(b.submission.createdOn).getTime() - new Date(a.submission.createdOn).getTime()
  );

  return (
    <ListContainer>
      <List>
        {props.submissionsAndTasks.map((item, index) => {
          console.log(item);
          return (
            <ListItem key={index}>
              <ListMain>
                {item.task.type !== 'categorize' ? (
                  <a href={`/submission?id=${item.submission.id}`}>
                    <ListItemImg src={item.task.params.attachment.url} alt="Image" />
                  </a>
                ) : (
                  <span style={{ width: '125px', height: '100px' }}>
                    <ListItemImg src={item.task.params.attachment.url} alt="Image" />
                  </span>
                )}

                <ListItemInfo>
                  <ListItemTitle>{item.task.instruction}</ListItemTitle>
                  <ListItemExtraData>
                    <ListItemStatus
                      style={{
                        backgroundColor:
                          item.submission.state === 'new'
                            ? `rgb(255, 153, 0)`
                            : item.submission.state === 'valid'
                            ? `rgb(0, 153, 0)`
                            : `rgb(255, 0, 0)`,
                      }}
                    >
                      {item.submission.state === 'valid'
                        ? 'Korrekt'
                        : item.submission.state === 'invalid'
                        ? 'Abgelehnt'
                        : 'Offen'}
                    </ListItemStatus>
                    <ListItemDate>
                      {dateformat(new Date(item.submission.createdOn), 'HH:MM dd.mm.yyyy')}
                    </ListItemDate>
                  </ListItemExtraData>
                </ListItemInfo>
              </ListMain>
              <TaxonomyList>
                {item.task.type === 'categorize'
                  ? (item.submission as TaxonomySubmission).result.taxonomies.map(taxonomy => {
                      return taxonomy.results.map((result, index) => {
                        return result !== 'Ja' && result !== 'Nein' ? (
                          <ListTaxonomyTags key={index}>{result}</ListTaxonomyTags>
                        ) : null;
                      });
                    })
                  : null}
              </TaxonomyList>
            </ListItem>
          );
        })}
      </List>
    </ListContainer>
  );
}
