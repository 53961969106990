import * as React from 'react';
import ReportDialog from '../../../shared/ReportDialog';

import { FormFooter, SecondaryButtonContainer } from '../../../shared/Forms';
import DoneButton from '../../../shared/buttons/DoneButton';

type Props = {
  isReadyToSubmit: boolean;
  onSkip: () => void;
  onDone: () => void;
  onReport: (reportMessage: string) => void;
};

export default function TaskFooter(props: Props) {
  const [isReportDialogOpen, setReportDialogOpen] = React.useState<boolean>(false);
  const [reportMessage, setReportMessage] = React.useState<string>('');
  return (
    <>
      <FormFooter>
        {/*
        It's important that the 'Done' button is first in the DOM, as it is auto-clicked on form
        submission (e.g. when pressing the 'Enter' key on a resktop). Clicking it submits the form.
      */}
        <DoneButton color="#22AF7E" label="Alles fertig!" disabled={!props.isReadyToSubmit} />

        <SecondaryButtonContainer
          buttonDefinitions={[
            { label: 'Überspringen', onClick: () => props.onSkip() },
            { label: 'Melden', onClick: () => setReportDialogOpen(true) },
          ]}
        />
      </FormFooter>

      <ReportDialog
        isOpen={isReportDialogOpen}
        handleCloseDialog={() => setReportDialogOpen(false)}
        reportMessage={reportMessage}
        onChangeReportMessage={setReportMessage}
        onReport={props.onReport}
      />
    </>
  );
}
