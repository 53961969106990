import * as React from 'react';
import styled from 'styled-components';

export const labelColors: string[] = [
  '#22AF7E',
  '#F77761',
  '#6b7fd2',
  'rgb(80, 114, 167)',
  '#00BDE1',
  'rgb(255, 170, 116)',
];

// Appropriate colors for fields that are called 'Yes' or 'No', with support for a few languages

const negativeLabelColor = '#F77761';
const positiveLabelColor = '#22AF7E';
const isPositiveLabel = (label: string) =>
  ['yes', 'ja', 'oui', 'si'].includes(label.toLocaleLowerCase());
const isNegativeLabel = (label: string) =>
  ['no', 'nein', 'non', 'no'].includes(label.toLocaleLowerCase());
export const getPresetLabelColor = (label: string) => {
  return isPositiveLabel(label)
    ? positiveLabelColor
    : isNegativeLabel(label)
    ? negativeLabelColor
    : undefined;
};

export const Form = styled.form``;

export const Fieldset = styled.section`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: start;
  &.centered {
    justify-content: center;
  }
`;

export const Input = styled.input`
  display: block;
  width: 0;
  height: 0;
  padding: 0;
  margin: 0;
  opacity: 0;
`;

export const InputLabel = styled.label`
  display: flex;
  justify-content: space-evenly;
  margin: 0.25rem;
  padding: 0.5rem 1rem;
  height: 1.125rem;
  line-height: 1.125rem;
  border-radius: 1em;
  color: white;
  background: #545454;
  cursor: pointer;
  font-weight: 700;
  transform: scale3d(0.95, 0.95, 0.95);
  &.is-checked {
    transform: scale3d(1, 1, 1);
  }
  transition: transform 0.15s ease-out, background-color 0.5s ease-out;
`;

/**
 * Reverses its order to allow a right-aligned submit button that is first in the DOM. It's
 * important that the 'Done' button is first in the DOM, as it is auto-clicked on form
 * submission (e.g. when pressing the 'Enter' key on a resktop). Clicking it submits the form.
 */

export const FormFooter = styled.footer`
  min-height: 100px;
  margin-top: 1rem;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
`;

export const SecondaryButtonContainerDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SecondaryButton = styled.button`
  appearance: none;
  padding: 0.5rem 1rem;
  margin: 0.25rem;
  border: none;
  border-radius: 0.25rem;
  outline: none;
  font-size: 1rem;
  background-color: #dddddd;
  color: white;
  font-size: 0.9rem;
  cursor: pointer;
`;

export function SecondaryButtonContainer(props: {
  buttonDefinitions: { label: string; onClick: () => void }[];
}) {
  return (
    <SecondaryButtonContainerDiv>
      {props.buttonDefinitions.map(def => (
        <SecondaryButton
          key={def.label}
          onClick={e => {
            e.preventDefault();
            def.onClick();
          }}
        >
          {def.label}
        </SecondaryButton>
      ))}
    </SecondaryButtonContainerDiv>
  );
}
