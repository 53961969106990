import * as React from 'react';
import ButtonIconWithLabel, { Props } from './ButtonIconWithLabel';

export default function DoneButton(props: Props) {
  const icon = (
    <path
      fill="#FFF"
      fillRule="nonzero"
      d="M29.46 42.856c-.966 0-1.757-.308-2.387-1.04l-5.888-6.665c-.616-.703-.806-1.26-.806-1.978 0-1.567 1.201-2.754 2.812-2.754.894 0 1.553.308 2.11.938l4.116 4.658 8.379-13.037c.674-1.025 1.377-1.42 2.446-1.42 1.626 0 2.857 1.186 2.857 2.738 0 .542-.176 1.158-.572 1.758L31.995 41.625c-.586.85-1.435 1.23-2.534 1.23z"
    />
  );
  return <ButtonIconWithLabel {...props}>{icon}</ButtonIconWithLabel>;
}
