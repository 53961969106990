import * as React from 'react';
import { Fieldset, Input, InputLabel } from './Forms';

export type Choice = {
  label: string;
  isChecked: boolean;
  colorWhenChecked: string;
};

type Props = {
  choices: Choice[];
  name: string;
  allowMultiple?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export default function ChoiceFieldSet(props: Props) {
  const { onChange, allowMultiple } = props;
  const type = allowMultiple ? 'checkbox' : 'radio';

  return (
    <Fieldset className={props.choices.length < 5 ? 'centered' : ''}>
      {props.choices.map((choice, i) => {
        const name = allowMultiple ? choice.label : 'choice';
        const value = allowMultiple ? undefined : choice.label;
        return (
          <InputLabel
            key={`${props.name}-${i}`}
            className={choice.isChecked ? 'is-checked' : ''}
            style={{
              backgroundColor: choice.isChecked ? choice.colorWhenChecked : '#545454',
            }}
          >
            <Input {...{ name, type, value, onChange, checked: choice.isChecked }} />
            <span>{choice.label}</span>
          </InputLabel>
        );
      })}
    </Fieldset>
  );
}
