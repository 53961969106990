import * as React from 'react';
import { pick, without, cloneDeep } from 'lodash';
import {
  createEmptyDrawingVariant,
  DrawingFeature,
  DrawingFeatureWithoutGeometry,
} from '../../../Utils/Models/AnnotateImage/Drawing';
import { Panel, ImageWithPanel } from '../../shared/ImageWithPanel';
import TaskComponent from '../TaskComponent';
import { AnnotationTask, Task } from '../../../Utils/Models/Task/Task';
import { DrawingVariant } from '../../../Utils/Models/Submission/DrawingVariant';
import FinishMessage from '../categorize/FinishMessage';
import TaskFooter from './footer/DefaultFooter';
import CancelOrConfirmFooter from './footer/CancelOrConfirmFooter';
import DeleteOrConfirmFooter from './footer/DeleteOrConfirmFooter';
import AnnotationNameForm from './AnnotationNameForm';
import { UIMode } from './UIMode';
import ExplanationPanelHeader from './ExplanationPanelHeader';
import { Rectangle } from './Rectangle';
import ImageContainerWithCanvas, { TransformState } from './ImageContainerWithCanvas';
import RectangleWithHandles, { invertTransform, transformRectangle } from './RectangleWithHandles';
import getRectangleForFeature, { getGeometryForRectangle } from './getRectangleForFeature';
import { labelColors } from '../../shared/Forms';
import PinchZoomPan from '../../shared/PinchZoomPan';
import { Submission } from '../../../Utils/Models/Submission/Submission';
import { AnnotationSubmission } from '../../../Utils/Models/Submission/AnnotationSubmission';

type Props = {
  onDone: (submission: Submission) => void;
  onReport: (reportMessage: string) => void;
  onSkip: () => void;
  task: Task;
};

type State = {
  mode: UIMode;
  currentFeature: DrawingFeatureWithoutGeometry | DrawingFeature;
  currentRectangle?: Rectangle;
  drawingVariant: DrawingVariant;
  zoomScale: number;
  isZooming: boolean;
  finishMessage?: string;
  transformState: TransformState;
  defaultTransformState?: TransformState;
};

export default class AnnotateRectanglesTaskUI extends TaskComponent<
  AnnotationTask,
  AnnotationSubmission,
  Props,
  State
> {
  pinchZoomPanRef = React.createRef<PinchZoomPan>();

  public state: State = {
    mode: 'onboarding',
    drawingVariant: createEmptyDrawingVariant(),
    currentFeature: this.createEmptyFeature(),
    zoomScale: 1,
    isZooming: false,
    transformState: { top: 0, left: 0, scale: 1 },
  };

  /**
   * Creates a feature without annotations and geometry to add to an image
   */
  createEmptyFeature(label?: string): DrawingFeatureWithoutGeometry {
    return {
      type: 'Feature',
      geometry: null,
      properties: {
        annotations: [
          {
            name: label || this.getAnnotationLabels()[0],
          },
        ],
      },
    };
  }

  saveResults = async () => {
    const submission: Submission = {
      userId: this.props.userId,
      hashKey: this.props.hashKey,
      taskId: this.props.task.id!,
      createdOn: new Date().toISOString(),
      type: this.props.task.type,
      result: {
        drawingVariant: this.state.drawingVariant,
      },
    };
    this.props.onDone(submission);
    this.setState({ mode: 'finished' });
  };

  report = (reportMessage: string) => {
    this.props.onReport(reportMessage);
    this.setState({ mode: 'finished', finishMessage: 'Thanks for reporting!' });
  };

  getImageUrl() {
    return this.props.task.params.attachment && this.props.task.params.attachment.url;
  }

  getCurrentAnnotationName() {
    const { annotations } = this.state.currentFeature.properties;
    const firstAnnotation = annotations[0];
    return firstAnnotation && firstAnnotation.name;
  }

  discardCurrentAnnotation = (zoomToDefaultScale: boolean) => {
    console.log('Discarding current annotation...');

    if (zoomToDefaultScale) {
      this.pinchZoomPanRef.current!.applyTransform(this.state.defaultTransformState!, 0.3);
    }
    this.setState({
      mode: 'overview',
      currentFeature: this.createEmptyFeature(this.getCurrentAnnotationName()),
      currentRectangle: undefined,
    });
  };

  removeFromConfirmedAnnotations = (feature: DrawingFeature) => {
    console.log('Removing current annotation from confirmed...');
    if (!feature.geometry) {
      return;
    }
    const { drawingVariant } = this.state;
    drawingVariant.featureCollection.features = without(
      drawingVariant.featureCollection.features,
      feature
    );
    this.setState({ drawingVariant: { ...drawingVariant } });
  };

  editCurrentAnnotation = () => {
    if (this.state.currentFeature.geometry) {
      if (this.state.currentRectangle) {
        this.state.currentFeature.geometry = getGeometryForRectangle(this.state.currentRectangle);
      }
      this.setState({
        currentFeature: this.createEmptyFeature(this.getCurrentAnnotationName()),
      });
    }
    this.discardCurrentAnnotation(true);
    return;
  };

  confirmCurrentAnnotation = () => {
    const { drawingVariant, currentRectangle } = this.state;
    if (!currentRectangle) {
      throw new Error('Need a defined rectangle to confirm annotation.');
    }

    const currentFeature: DrawingFeature = {
      ...cloneDeep(this.state.currentFeature),
      geometry: getGeometryForRectangle(currentRectangle),
    };

    drawingVariant.featureCollection.features = [
      ...drawingVariant.featureCollection.features.map(feature => ({ ...feature })),
      currentFeature,
    ];
    this.setState({ drawingVariant: { ...drawingVariant } });
    this.discardCurrentAnnotation(true);
  };

  setAnnotationName = (annotationName: string) => {
    if (!this.state.currentFeature) {
      return;
    }
    const { currentFeature } = this.state;
    currentFeature.properties.annotations = [{ name: annotationName }];
    this.setState({ currentFeature });
  };

  setAnnotationRectangle = (rectangleInScreenCoordinates: Rectangle) => {
    // Convert from screen to model coordinates
    const transform = invertTransform(this.state.transformState);
    const currentRectangle = transformRectangle(rectangleInScreenCoordinates, transform);
    console.log('Setting annotation rectangle', currentRectangle.offset, currentRectangle.bounds);
    this.setState({
      currentRectangle,
    });
  };

  onZoom = () => {
    console.log('zooming');

    const rectangle = this.state.currentRectangle;
    if (!rectangle) {
      return;
    }
    console.log('zooming react<');
    const oldScale = 1;

    const newScale = Math.min(
      (this.pinchZoomPanRef.current!.state as any).containerDimensions.width /
        (rectangle.bounds.width + 50),
      (this.pinchZoomPanRef.current!.state as any).containerDimensions.height /
        (rectangle.bounds.height + 50)
    );

    const scaleDiff = newScale / oldScale;

    const left = -rectangle.offset.width + 25;
    const top = -rectangle.offset.height + 25;

    const newTop = top * scaleDiff;
    const newLeft = left * scaleDiff;

    this.pinchZoomPanRef.current!.applyTransform(
      { top: newTop, left: newLeft, scale: newScale },
      0.3
    );
  };

  finishAnnotationRectangle = (rectangleInScreenCoordinates: Rectangle) => {
    console.log('Finishing annotation.');
    // Convert from screen to model coordinates
    this.setAnnotationRectangle(rectangleInScreenCoordinates);
    this.setState({ mode: 'confirmingAnnotation' });
    this.onZoom();
  };

  renderPanelFooter() {
    const hasAtLeastOneFeature = this.state.drawingVariant.featureCollection.features.length > 0;
    let footer = (
      <TaskFooter
        isReadyToSubmit={hasAtLeastOneFeature}
        onDone={() => this.saveResults()}
        {...pick(this.props, 'onSkip', 'onReport')}
      />
    );
    if (this.state.mode === 'confirmingAnnotation') {
      footer = (
        <CancelOrConfirmFooter
          canConfirm={!!this.state.currentRectangle && !!this.getCurrentAnnotationName()}
          onCancel={this.discardCurrentAnnotation}
          onConfirm={this.confirmCurrentAnnotation}
        />
      );
    } else if (this.state.mode === 'editingAnnotation') {
      footer = (
        <DeleteOrConfirmFooter
          onDelete={() => {
            if (!this.state.currentFeature.geometry) {
              return;
            }
            this.removeFromConfirmedAnnotations(this.state.currentFeature);
            this.discardCurrentAnnotation(true);
            this.setState({ mode: 'overview' });
          }}
          onConfirm={this.editCurrentAnnotation}
        />
      );
    }
    return footer;
  }

  getAnnotationLabels() {
    return this.props.task.params.annotations.map(a => a.name);
  }

  getAnnotationColor(label: string) {
    return labelColors[this.getAnnotationLabels().indexOf(label) % labelColors.length];
  }

  renderAnnotationNameForm() {
    return (
      <AnnotationNameForm
        annotationNames={this.getAnnotationLabels()}
        onChangeAnnotationName={this.setAnnotationName}
        selectedAnnotationName={this.getCurrentAnnotationName()}
        isReadyToSubmit={true}
        header={
          <ExplanationPanelHeader
            mode={this.state.mode}
            instruction={this.props.task.instruction}
          />
        }
        footer={this.renderPanelFooter()}
        onSubmit={() => {
          this.saveResults();
        }}
      />
    );
  }

  renderConfirmedAnnotations() {
    return this.state.drawingVariant.featureCollection.features
      .filter(feature => this.state.currentFeature !== feature)
      .map(feature => {
        const label = feature.properties.annotations[0].name;
        const rectangle = getRectangleForFeature(feature);
        return (
          <RectangleWithHandles
            key={String(feature.geometry.coordinates)}
            rectangle={rectangle}
            color={this.getAnnotationColor(label)}
            label={label}
            transformState={this.state.transformState}
            isSelected={this.state.currentFeature === feature}
            onResize={this.setAnnotationRectangle}
            onFinish={this.finishAnnotationRectangle}
            onZoom={this.onZoom}
            onSelect={event => {
              console.log('selecting');
              event.stopPropagation(); // do not fire touch handlers of useAnnotationCanvas
              event.preventDefault();
              this.setState({
                currentFeature: feature,
                currentRectangle: rectangle,
                // currentRectangle: rectangle,
                mode: 'editingAnnotation',
              });
            }}
          />
        );
      });
  }

  renderCurrentRectangle() {
    if (!this.state.currentRectangle) {
      return;
    }

    const label = this.getCurrentAnnotationName();
    return (
      <RectangleWithHandles
        rectangle={this.state.currentRectangle}
        color={this.getAnnotationColor(label)}
        label={label}
        transformState={this.state.transformState}
        isSelected={true}
        onResize={this.setAnnotationRectangle}
        onFinish={this.finishAnnotationRectangle}
        onZoom={this.onZoom}
      />
    );
  }

  updateTransform = (transformState: TransformState) => {
    console.log('Updating transform:', transformState);
    if (!this.state.defaultTransformState) {
      this.setState({
        transformState,
        defaultTransformState: { ...transformState },
      });
    } else {
      this.setState({ transformState });
    }
  };

  render() {
    if (this.state.mode === 'finished') {
      return (
        <FinishMessage
          finishMessage={
            this.state.finishMessage || '  Danke! Du kannst das Fenster nun schließen.'
          }
        />
      );
    }

    return (
      <ImageWithPanel>
        <ImageContainerWithCanvas
          mode={this.state.mode}
          imageSrc={this.props.task.params.attachment.url}
          onChange={this.setAnnotationRectangle}
          onFinish={this.finishAnnotationRectangle}
          onCancel={this.discardCurrentAnnotation}
          onTransform={this.updateTransform}
          innerRef={this.pinchZoomPanRef}
        >
          {this.renderConfirmedAnnotations()}
          {this.renderCurrentRectangle()}
        </ImageContainerWithCanvas>
        <Panel>{this.renderAnnotationNameForm()}</Panel>
      </ImageWithPanel>
    );
  }
}
