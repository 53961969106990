export type Size = { width: number; height: number };

export type Rectangle = {
  offset: Size;
  bounds: Size;
};

export function createRectangle(startOffset: Size, dragOffset: Size) {
  return {
    offset: startOffset,
    bounds: dragOffset,
  };
}

export function isValidSize(size: Size) {
  return Math.abs(size.width) >= 20 || Math.abs(size.height) >= 20;
}